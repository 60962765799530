<template>
  <Layout>
    <section class="csms-member">

      <div class="row" v-if="!$_utils.isEmpty(item)">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-xl-6">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                          <i class="uil uil-user"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="mb-1 text-truncate text-muted">
                        <span class="badge rounded-pill me-2 badge-outline-primary">{{ providerTitle(item.provider) }}</span>
                        <span class="me-2">{{ item.created }}</span>
                        <span>{{ $t('member.join') }}</span>
                      </p>
                      <h5 class="font-size-16 mb-0">
                        <span>{{ item.memberName }}</span>
                        <span class="text-primary ps-2 pe-2"> | </span>
                        <span>{{ item.email }}</span>
                        <template v-if="!$_utils.isEmpty(item.mobilePhoneNo)">
                          <span class="text-primary ps-2 pe-2"> | </span>{{ item.mobilePhoneNo }}
                        </template>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                          <i class="uil uil-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="mb-1 text-truncate text-muted">{{ $_formatter.conversionPlanName(item.planType) }}</p>
                      <h5 v-if="item.memberPlanMapping" class="font-size-16 mb-0">
                        <span>{{ item.memberPlanMapping.created }}</span>
                        <span class="ps-2">{{ $t('plan.planJoin') }}</span>
                        <span class="text-primary ps-2 pe-2"> | </span>
                        <span>{{ item.memberPlanMapping.endDate }}</span>
                        <span class="ps-2">{{ item.memberPlanMapping.isCancelReserved ? $t('plan.planStop') : $t('plan.renewal') }}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <hr class="mb-4" />

      <PageHeader :title="title" />

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="member-search-cp">{{ $t('member.placeName') }}</label>
                      <multiselect id="member-search-cp"
                                   label="text" track-by="value"
                                   v-model="searchForm.cp"
                                   :options="cpOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false"
                                   @select="selectCp" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="member-search-cb">{{ $t('member.chargeBoxName') }}</label>
                      <multiselect id="member-search-cb"
                                   label="text" track-by="value"
                                   v-model="searchForm.cb"
                                   :options="cbOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="member-search-period">{{ $t('member.approvalPeriod') }}</label>
                      <div>
                        <date-picker type="date" range
                                     :placeholder="$t('alert.selectDateRange')"
                                     v-model="searchForm.period" :clearable="false" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('member.noHistory')"
                         :fields="historyFields" :items="historyItems">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`member.${data.column}`) }}</span>
                  </template>

                  <template #cell(tid)="data">
                    {{ !$_utils.isEmpty(data.item.tid) ? data.item.tid : '-' }}
                  </template>
                  <template #cell(chargeEnergyKwh)="data">
                    {{ $_utils.comma(data.item.chargeEnergyKwh) }} kWh
                  </template>
                  <template #cell(chargeFee)="data">
                    {{ $_utils.comma(data.item.chargeFee) }}
                  </template>
                  <template #cell(authDate)="data">
                    {{ !$_utils.isEmpty(data.item.authDate) ? $_formatter.timestampToDate(data.item.authDate) : '-' }}
                  </template>
                  <template #cell(chargeTime)="data">
                    {{ data.item.chargeTime ? `${ data.item.startTimestamp.substring(11) } ~ ${ data.item.stopTimestamp.substring(11) } (${ data.item.chargeTime })` : '-' }}
                  </template>
                  <template #cell(memberPlanType)="data">
                    {{ $_formatter.conversionPlanName(data.item.memberPlanType) }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <div class="row mb-3">
        <div class="col-xl-12">
          <div class="float-end">
            <b-button variant="outline-secondary" @click="$router.go(-1)">
              <i class="uil uil-list-ul me-2"></i>
              <span>{{ $t('btn.list') }}</span>
            </b-button>
          </div>
        </div>
      </div>

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount
  },
  data() {
    return {
      title: 'chargeHistory',
      selectedId: null,
      item: null,
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      cpOptions: [],
      cbOptions: [],
      searchForm: {
        cp: { text: i18n.t('common.total'), value: null },
        cb: { text: i18n.t('common.total'), value: null },
        period: null
      },
      historyFields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'tid', label: '결제 ID', class: 'text-center' },
        { key: 'placeName', label: '충전소명', class: 'text-center' },
        { key: 'chargeBoxName', label: '충전기명', class: 'text-center' },
        { key: 'chargeBoxId', label: '충전기 ID', class: 'text-center' },
        { key: 'chargeEnergyKwh', label: '충전 용량', class: 'text-center' },
        { key: 'chargeFee', label: '결제 금액', class: 'text-center' },
        { key: 'authDate', label: '승인일', class: 'text-center' },
        { key: 'chargeTime', label: '충전 시간', class: 'text-center' },
        { key: 'memberPlanType', label: '회원 플랜', class: 'text-center' }
      ],
      historyItems: null
    }
  },
  created() {
    const vm = this;

    vm.selectedId = this.$route.params.id;
    vm.searchConditions = [{searchKey: 'memberNo', searchValue: vm.selectedId}];
  },
  mounted() {
    const vm = this;

    vm.getCpOptions();
    vm.getItem();
    vm.getHistoryItems();
  },
  methods: {
    ...loadingMethods,
    providerTitle(_type) {
      switch (_type) {
        case 'local': return i18n.t('common.email');
        case 'apple': return i18n.t('common.apple');
        case 'google': return i18n.t('common.google');
        default: return '-';
      }
    },
    getCpOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      vm.cpOptions = [{ text: i18n.t('common.total'), value: null }];

      http.post(_url, {
        page: 1,
        limit: 1000,
        searchConditions: [],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        res.data.items.forEach(item => {
          vm.cpOptions.push({ text: item.placeName, value: item.placeId });
        });

        vm.getCbOptions();
      }).catch(err => {
        console.error('Charge Place List Error :: ', err)
      });
    },
    getCbOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/list';

      vm.cbOptions = [{ text: i18n.t('common.total'), value: null }];
      vm.searchForm.cb = { text: i18n.t('common.total'), value: null };

      if (!this.$_utils.isEmpty(vm.searchForm.cp.value)) {
        http.post(_url, {
          page: 1,
          limit: 1000,
          searchConditions: [{ 'searchKey': 'placeId', 'searchValue': vm.searchForm.cp.value }],
          sortCondition: { sortDirection: 'DESC' }
        }).then(res => {
          res.data.items.forEach(item => {
            vm.cbOptions.push({ text: item.chargeBoxName, value: item.chargeBoxId });
          });
        }).catch(err => {
          console.error('Charge Point List Error :: ', err)
        });
      }
    },
    selectCp() {
      const vm = this;
      vm.getCbOptions();
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/MemberInfo/retrieve/${vm.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
      }).catch((err) => {
        console.error('Member Retrieve Error !!', err)
      });
    },
    reset() {
      const vm = this;

      vm.searchForm = {
        cp: { text: i18n.t('common.total'), value: null },
        cb: { text: i18n.t('common.total'), value: null },
        period: null
      };
      vm.searchConditions = [{searchKey: 'memberNo', searchValue: vm.selectedId}];
      vm.cbOptions = [{ text: i18n.t('common.total'), value: null }];

      vm.getHistoryItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [{searchKey: 'memberNo', searchValue: vm.selectedId}];

      if (!this.$_utils.isEmpty(vm.searchForm.cp.value)) {
        vm.searchConditions.push({ searchKey: 'placeId', searchValue: vm.searchForm.cp.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.cb.value)) {
        vm.searchConditions.push({ searchKey: 'chargeBoxId', searchValue: vm.searchForm.cb.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.period)) {
        vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD 00:00:00') });
        vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD 23:59:59') });
      }

      vm.pagination.currentPage = 1;
      vm.getHistoryItems();
    },
    async getHistoryItems() {
      const vm = this;
      const _url = '/onmapi/view/Payment/order/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.historyItems = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Member History List Error :: ', err)
      });

      vm.hideOverlay();
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        await vm.getHistoryItems();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        await vm.getHistoryItems();
      }
    }
  }
}
</script>
